import { render, staticRenderFns } from "./form-meal-recommendation.vue?vue&type=template&id=4c3dec9a&"
import script from "./form-meal-recommendation.vue?vue&type=script&lang=js&"
export * from "./form-meal-recommendation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CButton: require('@chakra-ui/vue').CButton, CTooltip: require('@chakra-ui/vue').CTooltip, CFlex: require('@chakra-ui/vue').CFlex, CText: require('@chakra-ui/vue').CText})
