var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "margin-top": "24px"
    }
  }, [_c('Tabs', {
    attrs: {
      "active-tab": "meal-plan",
      "mode": "edit",
      "is-program-consultation-basic": _vm.isProgramConsultationBasic,
      "tabs": _vm._tabsRealTime
    }
  }, [!_vm.isLoadingDraft ? _c('c-box', [_vm.tab === _vm.constants.tabsMealPlan['meal-plan'] ? _c('FormMealPlan', {
    ref: "formMealPlan",
    attrs: {
      "draft": _vm.draft,
      "preview": _vm.dataPreview.optConditions,
      "sub-tabs": _vm._subTabsRealTime
    },
    on: {
      "update:validation": _vm.onChangeValidation,
      "update:skip-meal-time": _vm.onChangeSkipStatus
    }
  }) : _vm._e(), _vm.tab === _vm.constants.tabsMealPlan['meal-recommendation'] ? _c('FormMealRecommendation', {
    ref: "formMealRecommendation",
    attrs: {
      "draft": _vm.draft,
      "is-draft-exist": !!_vm.draft,
      "sub-tabs": _vm._subTabsRealTime
    },
    on: {
      "update:validation": _vm.onChangeValidation
    }
  }) : _vm._e()], 1) : _c('c-box', {
    attrs: {
      "text-align": "center",
      "margin-block": "36px"
    }
  }, [_c('c-spinner', {
    attrs: {
      "thickness": "4px",
      "speed": "0.65s",
      "empty-color": "green.200",
      "color": "primary.400",
      "size": "xl"
    }
  }), _c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-weight": "500"
    }
  }, [_vm._v(" Memuat data Rencana Makan... ")])], 1)], 1), _c('ModalSimpleInfo', {
    attrs: {
      "is-open": _vm.showModalConfirm,
      "image": require('@/assets/images/image-question.svg'),
      "title": "Data belum tersimpan, yakin mau keluar halaman?"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding": "0 24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined",
            "is-disabled": _vm.isLoadingSave
          },
          on: {
            "click": _vm.onCloseModalConfirm
          }
        }, [_vm._v(" Tidak ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "is-loading": _vm.isLoadingSave
          },
          on: {
            "click": _vm.onSubmitModalConfirm
          }
        }, [_vm._v(" Ya ")])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }