var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-flex', {
    attrs: {
      "align-items": "center",
      "justify-content": "space-between"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "ghost",
      "width": "30px",
      "min-width": "30px",
      "height": "30px",
      "margin": "0",
      "padding": "0",
      "margin-right": "16px"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push(Object.assign({}, _vm.$route, {
          name: 'nutri.meal-plan.meal-plan-schedule.detail'
        }));
      }
    }
  }, [_c('c-box', {
    attrs: {
      "width": ['12px', '15px'],
      "height": ['12px', '15px']
    }
  }, [_c('inline-svg', {
    staticStyle: {
      "transform": "rotate(90deg)"
    },
    attrs: {
      "src": require('@/assets/icons/icon-arrow-down.svg'),
      "height": "100%",
      "width": "100%",
      "fill": "#008C81"
    }
  })], 1)], 1), _c('TabsMealPlan', {
    attrs: {
      "mode": "edit",
      "tabs": _vm.subTabs,
      "is-loading": _vm.isLoadingSave || _vm.statusSave === null
    }
  }), _c('c-tooltip', {
    attrs: {
      "label": _vm.iconCloud.text,
      "placement": "bottom"
    }
  }, [_c('c-box', {
    class: _vm.statusSave === null ? 'animate-spin' : '',
    attrs: {
      "width": ['35px'],
      "height": ['35px'],
      "margin-right": "16px"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": _vm.iconCloud.icon,
      "height": "100%",
      "width": "100%",
      "fill": "#008C81"
    }
  })], 1)], 1), _c('BaseButton', {
    attrs: {
      "left-svg-icon": require('@/assets/icons/icon-save.svg'),
      "left-svg-icon-color": "white",
      "disabled": !_vm.isValidAllMealPlanForm || _vm.statusSave === null || _vm.isLoadingSave
    },
    on: {
      "click": _vm.onSave
    }
  }, [_vm._v(" Simpan ")])], 1), _c('c-flex', {
    attrs: {
      "margin-top": "32px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "20px",
      "font-weight": "700"
    }
  }, [_vm._v(" Dibatasi ")])], 1), _c('BaseDivider'), _c('c-text', {
    attrs: {
      "font-size": "16px",
      "font-weight": "400",
      "margin-top": "16px",
      "margin-bottom": "8px"
    }
  }, [_vm._v(" Makanan yang dibatasi ")]), _c('BaseEditor', {
    attrs: {
      "image-provider-url": "/v1/nutritionist/meal-plans/upload"
    },
    on: {
      "blur": _vm.$v.form.limited.$touch
    },
    model: {
      value: _vm.form.limited,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "limited", $$v);
      },
      expression: "form.limited"
    }
  }), _vm.isInvalidField(_vm.$v.form.limited) ? _c('c-text', {
    attrs: {
      "color": "#D32737"
    }
  }, [_vm._v(" Cek ulang data ")]) : _vm._e(), _c('c-flex', {
    attrs: {
      "margin-top": "32px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "20px",
      "font-weight": "700"
    }
  }, [_vm._v(" Dihindari ")])], 1), _c('BaseDivider'), _c('c-text', {
    attrs: {
      "font-size": "16px",
      "font-weight": "400",
      "margin-top": "16px",
      "margin-bottom": "8px"
    }
  }, [_vm._v(" Makanan yang dihindari ")]), _c('BaseEditor', {
    attrs: {
      "image-provider-url": "/v1/nutritionist/meal-plans/upload"
    },
    on: {
      "blur": _vm.$v.form.avoided.$touch
    },
    model: {
      value: _vm.form.avoided,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "avoided", $$v);
      },
      expression: "form.avoided"
    }
  }), _vm.isInvalidField(_vm.$v.form.avoided) ? _c('c-text', {
    attrs: {
      "color": "#D32737"
    }
  }, [_vm._v(" Cek ulang data ")]) : _vm._e(), _c('ModalSimpleInfo', {
    attrs: {
      "is-open": _vm.isOpenModalSuccessSaveData,
      "image": require('@/assets/images/image-question.svg'),
      "title": "Data Rencana Makan berhasil disimpan"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding": "0 24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push(Object.assign({}, _vm.$route, {
                name: 'nutri.meal-plan.menu-recommendation.detail',
                params: {
                  bypass: 1
                }
              }));
            }
          }
        }, [_vm._v(" Oke ")])], 1)];
      },
      proxy: true
    }])
  }), _c('ModalSimpleInfo', {
    attrs: {
      "is-open": _vm.isOpenModalFailedSaveData,
      "image": require('@/assets/images/image-question.svg'),
      "title": "Ups, Gagal Menyimpan Data",
      "description": "Mohon maaf, sepertinya terdapat kesalahan data atau data belum terisi semua. Mohon untuk mengecek ulang data anda."
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding": "0 24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModalFailedSaveData = false;
            }
          }
        }, [_vm._v(" Konfirmasi ")])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }