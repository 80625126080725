<template>
  <c-box
    margin-top="24px"
  >
    <Tabs
      active-tab="meal-plan"
      mode="edit"
      :is-program-consultation-basic="isProgramConsultationBasic"
      :tabs="_tabsRealTime"
    >
      <c-box
        v-if="!isLoadingDraft"
      >
        <FormMealPlan 
          v-if="tab === constants.tabsMealPlan['meal-plan']"
          ref="formMealPlan"
          :draft="draft"
          :preview="dataPreview.optConditions"
          :sub-tabs="_subTabsRealTime"
          @update:validation="onChangeValidation"
          @update:skip-meal-time="onChangeSkipStatus"
        />
        <FormMealRecommendation
          v-if="tab === constants.tabsMealPlan['meal-recommendation']"
          ref="formMealRecommendation"
          :draft="draft"
          :is-draft-exist="!!draft"
          :sub-tabs="_subTabsRealTime"
          @update:validation="onChangeValidation"
        />
      </c-box>
      <c-box
        v-else
        text-align="center"
        margin-block="36px"
      >
        <c-spinner
          thickness="4px"
          speed="0.65s"
          empty-color="green.200"
          color="primary.400"
          size="xl"
        />
        <c-text
          color="primary.400"
          font-weight="500"
        >
          Memuat data Rencana Makan...
        </c-text>
      </c-box>
    </Tabs>

    <ModalSimpleInfo
      :is-open="showModalConfirm"
      :image="require('@/assets/images/image-question.svg')"
      title="Data belum tersimpan, yakin mau keluar halaman?"
    >
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding="0 24px"
          margin-bottom="24px"
        >
          <BaseButton
            :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
            left-svg-icon-color="#008C81"
            color="primary"
            rounded="1000px"
            width="100%"
            variant="outlined"
            :is-disabled="isLoadingSave"
            @click="onCloseModalConfirm"
          >
            Tidak
          </BaseButton>
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            color="primary"
            rounded="1000px"
            width="100%"
            :is-loading="isLoadingSave"
            @click="onSubmitModalConfirm"
          >
            Ya
          </BaseButton>
        </c-flex>
      </template>
    </ModalSimpleInfo>
  </c-box>
</template>

<script>
import _ from 'lodash'
import Tabs from '@/components/meal-plan/_widgets/tabs.vue'
import BaseButton from '@/components/elements/base-button.vue'
import { reqNutriMealPlan } from '@/requests/dietela-api/nutritionist/meal-plans'
import { reqNutritionist_mealPlans_preview } from '@/requests/dietela-api/nutritionist/other'
import {
  tabs, tabsMealPlan,
} from '@/constants/meal-plans'
import { mapGetters, mapMutations } from 'vuex'
import FormMealPlan from '@/components/meal-plan/_widgets/form-meal-plan.vue'

import FormMealRecommendation
  from '@/components/meal-plan/_widgets/form-meal-recommendation.vue'
import ModalSimpleInfo from '@/components/elements/modal-simple-info.vue'
import { CFlex } from '@chakra-ui/vue'
import mixinsTabsMealPlan from '@/mixins/mixins-tabs-meal-plan'

export default {
  components: {
    CFlex, ModalSimpleInfo,
    BaseButton,
    FormMealPlan,
    FormMealRecommendation,
    Tabs,
  },
  // beforeRouteUpdate(to, from, next) {
  //   if (!this.saveState) {
  //     this.showModalConfirm = true
  //     this.to = to
  //     return
  //   }
  //   next()
  // },
  mixins: [mixinsTabsMealPlan],
  beforeRouteLeave(to, from, next) {
    if (this.to || to.params?.bypass || this.saveState || !this.isAuthenticated) {
      next()
    } else {
      this.to = to
      this.showModalConfirm = true
    }
  },
  props: {
    isEdited: {
      type: Boolean,
      default: true,
    },
    isProgramConsultationBasic: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      to: null,
      showModalConfirm: false,
      draft: null,
      isLoadingDraft: true,
      isLoadingSave: false,
      dataPreview: {
        optConditions: {},
      },
      validation: {
        'meal-plan': {
          breakfast: 1,
          morningSnack: 1,
          lunch: 1,
          afternoonSnack: 1,
          dinner: 1,
          nightSnack: 1,
        },
        'meal-recommendation': 1,
      },
    }
  },
  computed: {
    ...mapGetters({
      saveState: 'general/saveState',
      isAuthenticated: 'auth/isAuthenticated',
    }),
    tab() {
      return this.$route.query?.tab ?? this.constants.tabsMealPlan['meal-plan']
    },
    constants() {
      return {
        tabs,
        tabsMealPlan,
      }
    },
    isFormDisabled() {
      return !this.draft?.nutritionalConditions || this.draft?.nutritionalConditions?.validation === -1
    },
    _tabsRealTime() {
      const isMealPlanSchedulesValid = Object.values(this.validation['meal-plan']).every((val) => val === 1)

      return [
        { 
          ...this._tabs[0],
        },
        { 
          ...this._tabs[1],
          validation: isMealPlanSchedulesValid && this.validation['meal-recommendation'] === 1 ? 1 : -1,
        },
        {
          ...this._tabs[2],
        },
      ]
    },
    _subTabsRealTime() {
      const isMealPlanSchedulesValid = Object.values(this.validation['meal-plan']).every((val) => val === 1)

      return [
        {
          ...this._subTabs[0],
          validation: isMealPlanSchedulesValid ? 1 : -1,
        },
        {
          ...this._subTabs[1],
          validation: this.validation['meal-recommendation'],
        },
      ]
    },
  },
  watch: {
    $route() {
      this.init()
      this.getMenuRecommendationWeekDays()
    },
    isAuthenticated: {
      handler(val) {
        if (!val) {
          window.removeEventListener('beforeunload', this.onPreventNav)
          this.$router.replace({
            name: 'auth',
            params: {
              bypass: 1,
            },
            query: {
              redirect: this.$route.path,
            },
          })
        }
      },
    },
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.onPreventNav)
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.onPreventNav)
  },
  created() {
    this.verifyCurrentTab()
    this.init()
  },
  methods: {
    ...mapMutations({
      setNutritionalConditions: 'mealPlan/setNutritionalConditions',
      setMealPlanPreview: 'mealPlan/setMealPlanPreview',
      setMenuRecommendationWeeksDays: 'mealPlan/setMenuRecommendationWeeksDays',
    }),
    verifyCurrentTab() {
      if (!(Object.values(this.constants.tabsMealPlan)).includes(this.$route.query?.tab)) {
        this.$router.replace({
          name: 'nutri.meal-plan.meal-plan-schedule.edit',
          params: this.$route.params,
          query: {
            tab: this.constants.tabsMealPlan['meal-plan'],
          },
        })
      }
    },
    async init() {
      try {
        this.isLoadingDraft = true
        const res = await reqNutriMealPlan.getMealPlanData(this.$store.getters.axios, {
          clientId: this.$route.params.clientId,
          programId: this.$route.params.programId,
          month: this.$route.params?.month,
        })
        this.setNutritionalConditions(res.data?.data?.nutritionalConditions)
  
        const resPreviewOptConditions = await reqNutritionist_mealPlans_preview(this.$store.getters.axios,
          {
            clientId: this.$route.params.clientId,
            programId: this.$route.params.programId,
            month: this.$route.params?.month,
          })
        this.dataPreview.optConditions = resPreviewOptConditions?.data?.data || {}
  
        if (res.data?.data && !_.isEmpty(res.data?.data)) {
          this.draft = res.data.data
          this.setMealPlanPreview(res?.data?.data)
        }
        this.onUpdateValidationWithDraft()
      } catch (err) {
        //
      } finally {
        this.isLoadingDraft = false
      }
    },
    onCloseModalConfirm() {
      if (!this.isLoadingSave) {
        this.showModalConfirm = false
        this.to = null
      }
    },
    async onSubmitModalConfirm() {
      this.isLoadingSave = true
      try {
        switch (this.tab) {
          case this.constants.tabsMealPlan['meal-plan']: {
            if (!this.isFormDisabled) {
              await this.$refs.formMealPlan.onSaveDraft()
            }
            break
          }
          case this.constants.tabsMealPlan['meal-recommendation']:
            await this.$refs.formMealRecommendation.onSaveDraft()
            break
        }
      } finally {
        this.isLoadingSave = false
        this.$router.push(this.to)
      }
    },
    onPreventNav(event) {
      if (this.statusSave) return
      event.preventDefault()
      event.returnValue = ''
    },
    async getMenuRecommendationWeekDays() {
      const res = await reqNutriMealPlan.getMenuRecommendationWeekDays(this.$store.getters.axios,
        {
          clientId: this.$route.params?.clientId,
          programId: this.$route.params?.programId,
          month: this.$route.params.month,
        },
      )
      this.setMenuRecommendationWeeksDays(res?.data?.data)
    },
    onUpdateValidationWithDraft() {
      const isMealPlanSchedulesExist = this.draft?.mealPlanSchedules && this.draft?.mealPlanSchedules?.length > 0
      
      if (!isMealPlanSchedulesExist) {
        for (let key in this.validation['meal-plan'])
          this.validation['meal-plan'][key] = 0
      } else {
        const validationMealPlanSchedule = this.draft?.mealPlanSchedules?.map((it) => {
          return {
            [it?.mealTime]: it?.validation,
          }
        })
        
        const combinedMealTimes = validationMealPlanSchedule.reduce((acc, obj) => {
          return { ...acc, ...obj }
        }, {})

        this.validation['meal-plan'] = combinedMealTimes
      }

      this.validation['meal-recommendation'] = this.draft?.mealRecommendations?.validation || 0
    },
    onChangeValidation(value) {
      if (value?.form === 'meal-plan') {
        this.validation[value?.form][value?.mealTime] = value?.validation  
      } else {
        this.validation[value?.form] = value?.validation
      }
    },
    onChangeSkipStatus() {
      this.getMenuRecommendationWeekDays()
    },
  },
}
</script>
