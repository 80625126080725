<template>
  <c-box>
    <c-flex
      align-items="center"
      justify-content="space-between"
    >
      <c-button
        variant="ghost"
        width="30px"
        min-width="30px"
        height="30px"
        margin="0"
        padding="0"
        margin-right="16px"
        @click="
          $router.push({
            ...$route,
            name: 'nutri.meal-plan.meal-plan-schedule.detail',
          })
        "
      >
        <!--        // statusSave-->
        <!--        //   ? $router.push({-->
        <!--        //     ...$route,-->
        <!--        //     name: 'nutri.meal-plan.meal-plan-schedule.detail',-->
        <!--        //   })-->
        <!--        //   : (isOpenModalBackToPreview = true)-->
        <c-box
          :width="['12px', '15px']"
          :height="['12px', '15px']"
        >
          <inline-svg
            :src="require('@/assets/icons/icon-arrow-down.svg')"
            height="100%"
            width="100%"
            fill="#008C81"
            style="transform: rotate(90deg)"
          />
        </c-box>
      </c-button>
      <TabsMealPlan 
        mode="edit"
        :tabs="subTabs"
        :is-loading="isLoadingSave || statusSave === null"
      />
      <c-tooltip
        :label="iconCloud.text"
        placement="bottom"
      >
        <c-box
          :width="['35px']"
          :height="['35px']"
          margin-right="16px"
          :class="statusSave === null ? 'animate-spin' : ''"
        >
          <inline-svg
            :src="iconCloud.icon"
            height="100%"
            width="100%"
            fill="#008C81"
          />
        </c-box>
      </c-tooltip>
      <BaseButton
        :left-svg-icon="require('@/assets/icons/icon-save.svg')"
        left-svg-icon-color="white"
        :disabled="!isValidAllMealPlanForm || statusSave === null || isLoadingSave"
        @click="onSave"
      >
        Simpan
      </BaseButton>
    </c-flex>

    <!-- XXX -->
    <c-flex margin-top="32px">
      <c-text
        font-size="20px"
        font-weight="700"
      >
        Dibatasi
      </c-text>
    </c-flex>
    <BaseDivider />
    <c-text
      font-size="16px"
      font-weight="400"
      margin-top="16px"
      margin-bottom="8px"
    >
      Makanan yang dibatasi
    </c-text>
    <BaseEditor
      v-model="form.limited"
      image-provider-url="/v1/nutritionist/meal-plans/upload"
      @blur="$v.form.limited.$touch"
    />
    <c-text
      v-if="isInvalidField($v.form.limited)"
      color="#D32737"
    >
      Cek ulang data
    </c-text>

    <!-- XXX -->
    <c-flex margin-top="32px">
      <c-text
        font-size="20px"
        font-weight="700"
      >
        Dihindari
      </c-text>
    </c-flex>
    <BaseDivider />
    <c-text
      font-size="16px"
      font-weight="400"
      margin-top="16px"
      margin-bottom="8px"
    >
      Makanan yang dihindari
    </c-text>
    <BaseEditor
      v-model="form.avoided"
      image-provider-url="/v1/nutritionist/meal-plans/upload"
      @blur="$v.form.avoided.$touch"
    />
    <c-text
      v-if="isInvalidField($v.form.avoided)"
      color="#D32737"
    >
      Cek ulang data
    </c-text>

    <!--    <ModalSimpleInfo-->
    <!--      :is-open="isOpenModalBackToPreview"-->
    <!--      :image="require('@/assets/images/image-question.svg')"-->
    <!--      title="Data belum tersimpan, yakin mau keluar halaman?"-->
    <!--    >-->
    <!--      <template #footer>-->
    <!--        <c-flex-->
    <!--          gap="20px"-->
    <!--          width="100%"-->
    <!--          padding="0 24px"-->
    <!--          margin-bottom="24px"-->
    <!--        >-->
    <!--          <BaseButton-->
    <!--            :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"-->
    <!--            left-svg-icon-color="#008C81"-->
    <!--            color="primary"-->
    <!--            rounded="1000px"-->
    <!--            width="100%"-->
    <!--            variant="outlined"-->
    <!--            @click="isOpenModalBackToPreview = false"-->
    <!--          >-->
    <!--            Tidak-->
    <!--          </BaseButton>-->
    <!--          <BaseButton-->
    <!--            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"-->
    <!--            right-svg-icon-color="white"-->
    <!--            color="primary"-->
    <!--            rounded="1000px"-->
    <!--            width="100%"-->
    <!--            @click="-->
    <!--              $router.push({-->
    <!--                ...$route,-->
    <!--                name: 'nutri.meal-plan.meal-plan-schedule.detail',-->
    <!--              })-->
    <!--            "-->
    <!--          >-->
    <!--            Ya-->
    <!--          </BaseButton>-->
    <!--        </c-flex>-->
    <!--      </template>-->
    <!--    </ModalSimpleInfo>-->

    <ModalSimpleInfo
      :is-open="isOpenModalSuccessSaveData"
      :image="require('@/assets/images/image-question.svg')"
      title="Data Rencana Makan berhasil disimpan"
    >
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding="0 24px"
          margin-bottom="24px"
        >
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            color="primary"
            rounded="1000px"
            width="100%"
            @click="
              $router.push({
                ...$route,
                name: 'nutri.meal-plan.menu-recommendation.detail',
                params: {
                  bypass: 1,
                }
              })
            "
          >
            Oke
          </BaseButton>
        </c-flex>
      </template>
    </ModalSimpleInfo>

    <ModalSimpleInfo
      :is-open="isOpenModalFailedSaveData"
      :image="require('@/assets/images/image-question.svg')"
      title="Ups, Gagal Menyimpan Data"
      description="Mohon maaf, sepertinya terdapat kesalahan data atau data belum terisi semua. Mohon untuk mengecek ulang data anda."
    >
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding="0 24px"
          margin-bottom="24px"
        >
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            color="primary"
            rounded="1000px"
            width="100%"
            @click="isOpenModalFailedSaveData = false"
          >
            Konfirmasi
          </BaseButton>
        </c-flex>
      </template>
    </ModalSimpleInfo>
  </c-box>
</template>

<script>
import { CBox, CButton, CFlex, CText } from '@chakra-ui/vue'
import BaseButton from '@/components/elements/base-button.vue'
import TabsMealPlan from '@/components/meal-plan/_widgets/tab-meal-plan.vue'
import BaseDivider from '@/components/elements/base-divider.vue'
import BaseEditor from '@/components/elements/base-editor.vue'
import { reqNutriMealPlan } from '@/requests/dietela-api/nutritionist/meal-plans'
import _ from 'lodash'
import { required } from 'vuelidate/lib/validators'
import { isInvalidField } from '@/utils/vuelidate-helpers/is-invalid-field'
import ModalSimpleInfo from '@/components/elements/modal-simple-info.vue'
import { mapMutations } from 'vuex'

export default {
  name: 'FormMealRecommendation',
  components: {
    ModalSimpleInfo,
    BaseEditor,
    CText,
    BaseDivider,
    CButton,
    TabsMealPlan,
    BaseButton,
    CFlex,
    CBox,
  },
  props: {
    draft: {
      type: [Object, null],
      default: null,
    },
    subTabs: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:validation'],
  data() {
    return {
      // isOpenModalBackToPreview: false,
      isOpenModalSuccessSaveData: false,
      isOpenModalFailedSaveData: false,
      isLoadingSave: false,
      statusSave: true,
      formDuplicate: null,
      form: {
        limited: '',
        avoided: '',
      },
    }
  },
  computed: {
    isValidAllMealPlanForm() {
      if (!this.draft?.mealPlanSchedules) {
        return false
      }
      return this.draft?.mealPlanSchedules?.every((v) => v.validation === 1)
          && !this.$v.$invalid // this.draft?.mealRecommendations?.validation === 1
    },
    form2() {
      return this.form
    },
    iconCloud() {
      switch (this.statusSave) {
        case true:
          return {
            icon: require('@/assets/icons/icon-cloud-done.svg'),
            text: 'Semua perubahan telah disimpan',
          }
        case false:
          return {
            icon: require('@/assets/icons/icon-cloud-off.svg'),
            text: 'Koneksi terputus, perubahan tidak tersimpan',
          }
        default:
          return {
            icon: require('@/assets/icons/icon-sync.svg'),
            text: 'Loading...',
          }
      }
    },
  },
  watch: {
    form2: {
      handler() {
        this.setSaveState(null)
        this.statusSave = null
      },
      deep: true,
    },
    form: {
      handler: _.debounce(function() {
        const data = {
          form: 'meal-recommendation',
          validation: this.$v.form.$invalid ? -1 : 1,
        }
        this.$emit('update:validation', data)

        this.onSaveDraft()
      }, 250),
      deep: true,
    },
  },
  mounted() {
    if (this.draft?.mealRecommendations && !_.isEmpty(this.draft?.mealRecommendations)) {
      this.form = this.draft?.mealRecommendations
      this.$v.$touch()
    }
    setTimeout(() => {
      this.isLoadingData = false
    }, 1000)
    this.formDuplicate = _.cloneDeep(this.form)
  },
  methods: {
    ...mapMutations({
      setSaveState: 'general/setSaveState',
      setEditValidation: 'mealPlan/setEditValidation',
    }),
    isInvalidField,
    async onSave() {
      try {
        await this.onSaveDraft()
        this.isOpenModalSuccessSaveData = true
      } catch (err) {
        //
      }
    },
    async onSaveDraft() {
      try {
        this.isLoadingSave = true

        let payload = {
          clientId: this.$route.params.clientId,
          programId: this.$route.params.programId,
          month: this.$route.params.month,
          mealRecommendations: {
            ...this.form,
            validation: this.$v.$invalid ? -1 : 1,
          },
        }

        await reqNutriMealPlan.updateMealPlanDraft(this.$store.getters.axios, { payload })

        this.$v.$touch()

        // if (isAllFormValid({
        //   nutritionalConditions: this.draft?.nutritionalConditions?.validation === 1,
        //   nutritionalIntake: this.draft?.nutritionalIntake?.validation === 1,
        //   bodyConditions: this.draft?.bodyConditions?.validation === 1,
        //   targetAndSuggestions: !this.$v?.$invalid,
        // })) {
        //   this.isOpenModalSuccessSaveData = true
        // } else {
        this.setSaveState(true)
        this.statusSave = true
        // }
        this.formDuplicate = _.cloneDeep(this.form)
        this.setEditValidation({
          routeName: this.$route.name,
          validation: this.isValidAllMealPlanForm ? 1 : -1,
        })
      } catch (err) {
        this.setSaveState(false)
        this.statusSave = false
        this.isOpenModalFailedSaveData = true
        throw new Error(err)
      } finally {
        this.isLoadingSave = false
      }
    },
  },
  validations() {
    return {
      form: {
        limited: {
          required,
        },
        avoided: {
          required,
        },
      },
    }
  },
}
</script>
