import { render, staticRenderFns } from "./accordion-meal-plan-menu.vue?vue&type=template&id=d663e466&"
import script from "./accordion-meal-plan-menu.vue?vue&type=script&lang=js&"
export * from "./accordion-meal-plan-menu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CBox: require('@chakra-ui/vue').CBox, CFlex: require('@chakra-ui/vue').CFlex, CInputLeftElement: require('@chakra-ui/vue').CInputLeftElement, CInput: require('@chakra-ui/vue').CInput, CInputRightElement: require('@chakra-ui/vue').CInputRightElement, CInputGroup: require('@chakra-ui/vue').CInputGroup, CFormHelperText: require('@chakra-ui/vue').CFormHelperText, CFormControl: require('@chakra-ui/vue').CFormControl, CCheckbox: require('@chakra-ui/vue').CCheckbox, CImage: require('@chakra-ui/vue').CImage, CButton: require('@chakra-ui/vue').CButton, CGrid: require('@chakra-ui/vue').CGrid, CIcon: require('@chakra-ui/vue').CIcon, CSpinner: require('@chakra-ui/vue').CSpinner})
